@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  font-family: 'Poppins', sans-serif;
}

button{
  color: hsl(214, 57%, 28%);
}

.logoImg img{
  width: 10rem ;
  padding: 1rem;
  margin: auto;
}

.cta{
  border: 2px solid white;
  border-radius: 10px;
}

@media screen and (max-width: 1023px) {
  .logoImg img{
    width: 10rem ;
    padding: 1rem;
    margin: auto;
  }

  .menu{
    position: fixed;
    top: 7rem;
    right: 2rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    height: fit-content;
    width: 70%;
    gap: .5rem;
    padding: 2rem 0;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transition: all 300ms ease-in;
    transform: translateX(100vw);
  }
  
  .responsive_nav{
    transform: none;
  }
}



